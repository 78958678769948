<template>
	<card style="flex: 1; maxheight: 100%">
		<h1 slot="title">Marketing Templates</h1>
		<el-table
			slot="table"
			:data="links"
			@row-click="downloadFile"
			:style="{ width: '100%', maxHeight: '650px', overflowY: 'scroll' }"
		>
			<el-table-column prop="title" label="Document Name">
			</el-table-column>
			<el-table-column align="right" label=" " width="150px">
				<template slot-scope="scope">
					<el-button @click="downloadFile(scope.row)" size="mini"
						>Download</el-button
					>
				</template>
			</el-table-column>
		</el-table>
	</card>
</template>

<script>
import Card from '../ui/Card'

export default {
	name: 'token-list',
	components: { Card },
	data() {
		return {
			links: [
				{
					title: 'READ ME FIRST - Checklist for Nonprofits',
					link:
						'https://drive.google.com/uc?export=download&id=1Yby2HnRH8u3MlSf6CkCz1f9pc8rJ8HAL'
				},
				{
					title: 'Most Popular Templates',
					link:
						'https://drive.google.com/uc?export=download&id=1VCwxpOPFUlPs1lZqA0a2rp0cX7Rr-Jzk'
				},
				{
					title: 'Social Media Posts',
					link:
						'https://drive.google.com/uc?export=download&id=1tIwtCPIIMro4waaDIwa5Yesr9rrX7s17'
				},
				{
					title: 'Email Blast Templates',
					link:
						'https://drive.google.com/uc?export=download&id=1hs3bNQS70y7hkoyEW0tEqQNmY6G4CrvZ'
				},
				{
					title: 'Text Message Templates',
					link:
						'https://drive.google.com/uc?export=download&id=1Nj0tHNL_wMLRSxWBgMP2N5Xb7ltUiU06'
				},
				{
					title: 'RoundUp App Logos',
					link:
						'https://drive.google.com/uc?export=download&id=1UZE_wEncbzSRlSSb1i7PJNfauHCxgYY2'
				},
				{
					title: 'Website Embed',
					link:
						'https://drive.google.com/uc?export=download&id=1y6njx1LhNGjXIw_1k_XAZ1Rz8uwgSs0Z'
				},
				{
					title: 'Newsletter Templates',
					link:
						'https://drive.google.com/uc?export=download&id=1xCZkd2XDzGyGqy_W2YBFTgxibBhU1NEk'
				}
			]
		}
	},
	methods: {
		downloadFile(row) {
			window.open(row.link, '_blank')
		}
	}
}
</script>
