var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"library"},[_c('div',{staticClass:"roundup-row"},[_c('card',{staticStyle:{"flex":"2"}},[_c('div',{style:({ position: 'relative' }),attrs:{"slot":"title"},slot:"title"},[_c('h1',[_vm._v("Marketing Library - Examples")]),_c('ui-loader',{attrs:{"loading":_vm.loading}})],1),_c('div',{staticClass:"roundup-row",attrs:{"slot":"body"},slot:"body"},[_vm._l((this.mainDocuments),function(file,idx){return _c('file-tile',{key:idx},[(file.meta.thumbs)?_c('img',{staticStyle:{"height":"80px","maxwidth":"220px"},attrs:{"name":"image","src":file.meta.thumbs.preview.friendly_url}}):(file.extension === 'pdf')?_c('img',{staticStyle:{"height":"65px","maxwidth":"240px"},attrs:{"name":"image","src":require("@/assets/PDF.png")}}):_vm._e(),_c('h4',{style:({
							marginTop: '5px',
							marginLeft: '10px',
							marginRight: '10px',
							marginBottom: '0'
						}),attrs:{"name":"title"}},[_vm._v(" "+_vm._s(file.name)+" ")]),_c('ui-button',{style:({ marginTop: '10px' }),attrs:{"slot":"button"},on:{"click":function($event){return _vm.downloadFile(file.url)}},slot:"button"},[_vm._v("Download")])],1)}),(this.facebookDocuments.length > 0)?_c('collapsible',{attrs:{"size":"large","open":false}},[_c('h1',{attrs:{"slot":"title"},slot:"title"},[_vm._v("Social Media Post Examples")]),_c('div',{staticClass:"roundup-row",style:({ maxHeight: '500px', overflowY: 'scroll' }),attrs:{"slot":"body"},slot:"body"},_vm._l((this.facebookDocuments),function(file,idx){return _c('file-tile',{key:idx},[(file.meta.thumbs)?_c('img',{staticStyle:{"height":"80px","maxwidth":"220px"},attrs:{"name":"image","src":file.meta.thumbs.preview.friendly_url}}):(file.extension === 'pdf')?_c('img',{staticStyle:{"height":"65px","maxwidth":"240px"},attrs:{"name":"image","src":require("@/assets/PDF.png")}}):_vm._e(),_c('h4',{style:({
									marginTop: '5px',
									marginLeft: '10px',
									marginRight: '10px',
									marginBottom: '0'
								}),attrs:{"name":"title"}},[_vm._v(" "+_vm._s(file.name)+" ")]),_c('ui-button',{style:({ marginTop: '10px' }),attrs:{"slot":"button"},on:{"click":function($event){return _vm.downloadFile(file.url)}},slot:"button"},[_vm._v("Download")])],1)}),1)]):_vm._e(),(this.videoDocuments.length > 0)?_c('collapsible',{attrs:{"size":"large","open":false}},[_c('h1',{attrs:{"slot":"title"},slot:"title"},[_vm._v("Video Examples")]),_c('div',{staticClass:"roundup-row",style:({ maxHeight: '500px', overflowY: 'scroll' }),attrs:{"slot":"body"},slot:"body"},_vm._l((this.videoDocuments),function(file,idx){return _c('file-tile',{key:idx},[(file.meta.thumbs)?_c('img',{staticStyle:{"height":"80px","maxwidth":"220px"},attrs:{"name":"image","src":file.meta.thumbs.preview.friendly_url}}):(file.extension === 'pdf')?_c('img',{staticStyle:{"height":"65px","maxwidth":"240px"},attrs:{"name":"image","src":require("@/assets/PDF.png")}}):_vm._e(),_c('h4',{style:({
									marginTop: '5px',
									marginLeft: '10px',
									marginRight: '10px',
									marginBottom: '0'
								}),attrs:{"name":"title"}},[_vm._v(" "+_vm._s(file.name)+" ")]),_c('ui-button',{style:({ marginTop: '10px' }),attrs:{"slot":"button"},on:{"click":function($event){return _vm.downloadFile(file.url)}},slot:"button"},[_vm._v("Download")])],1)}),1)]):_vm._e(),(this.emailDocuments.length > 0)?_c('collapsible',{attrs:{"size":"large","open":false}},[_c('h1',{attrs:{"slot":"title"},slot:"title"},[_vm._v("Email Examples")]),_c('div',{staticClass:"roundup-row",style:({ maxHeight: '500px', overflowY: 'scroll' }),attrs:{"slot":"body"},slot:"body"},_vm._l((this.emailDocuments),function(file,idx){return _c('file-tile',{key:idx},[(file.meta.thumbs)?_c('img',{staticStyle:{"height":"80px","maxwidth":"220px"},attrs:{"name":"image","src":file.meta.thumbs.preview.friendly_url}}):(file.extension === 'pdf')?_c('img',{staticStyle:{"height":"65px","maxwidth":"240px"},attrs:{"name":"image","src":require("@/assets/PDF.png")}}):_vm._e(),_c('h4',{style:({
									marginTop: '5px',
									marginLeft: '10px',
									marginRight: '10px',
									marginBottom: '0'
								}),attrs:{"name":"title"}},[_vm._v(" "+_vm._s(file.name)+" ")]),_c('ui-button',{style:({ marginTop: '10px' }),attrs:{"slot":"button"},on:{"click":function($event){return _vm.downloadFile(file.url)}},slot:"button"},[_vm._v("Download")])],1)}),1)]):_vm._e(),(this.websiteDocuments.length > 0)?_c('collapsible',{attrs:{"size":"large","open":false}},[_c('h1',{attrs:{"slot":"title"},slot:"title"},[_vm._v("Website Examples")]),_c('div',{staticClass:"roundup-row",style:({ maxHeight: '500px', overflowY: 'scroll' }),attrs:{"slot":"body"},slot:"body"},_vm._l((this.websiteDocuments),function(file,idx){return _c('file-tile',{key:idx},[(file.meta.thumbs)?_c('img',{staticStyle:{"height":"80px","maxwidth":"220px"},attrs:{"name":"image","src":file.meta.thumbs.preview.friendly_url}}):(file.extension === 'pdf')?_c('img',{staticStyle:{"height":"65px","maxwidth":"240px"},attrs:{"name":"image","src":require("@/assets/PDF.png")}}):_vm._e(),_c('h4',{style:({
									marginTop: '5px',
									marginLeft: '10px',
									marginRight: '10px',
									marginBottom: '0'
								}),attrs:{"name":"title"}},[_vm._v(" "+_vm._s(file.name)+" ")]),_c('ui-button',{style:({ marginTop: '10px' }),attrs:{"slot":"button"},on:{"click":function($event){return _vm.downloadFile(file.url)}},slot:"button"},[_vm._v("Download")])],1)}),1)]):_vm._e()],2)]),_c('div',{staticStyle:{"flex":"1"}},[_c('marketing-downloads')],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }