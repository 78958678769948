<template>
	<section class="file">
		<slot></slot>
		<div class="body">
			<slot name="image" :style="{ flex: 2 }"></slot>
			<slot
				name="title"
				:style="{ marginLeft: '20px', marginRight: '20px', flex: 1 }"
			></slot>
			<slot name="button" :style="{ flex: 1 }"></slot>
		</div>
	</section>
</template>

<script>
export default {
	name: 'roundup-card',
	props: [],
	computed: {}
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';
.file {
	background: $white;
	box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
	margin: 20px 15px 20px 15px;
	border-radius: 14px;
	border: 2px solid $roundup-primary;
	max-width: 225px;
	width: 225px;
	height: 225px;
	align-items: center;
	justify-content: space-around;
	display: flex;
	flex-direction: column;
	text-align: center;
	.body {
		padding-left: 20px;
		padding-right: 20px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
}
</style>
