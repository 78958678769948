<template>
	<div class="library">
		<div class="roundup-row">
			<card style="flex: 2">
				<div slot="title" :style="{ position: 'relative' }">
					<h1>Marketing Library - Examples</h1>
					<ui-loader :loading="loading" />
				</div>
				<div slot="body" class="roundup-row">
					<file-tile
						v-for="(file, idx) in this.mainDocuments"
						:key="idx"
					>
						<img
							style="height: 80px; maxwidth: 220px"
							name="image"
							:src="file.meta.thumbs.preview.friendly_url"
							v-if="file.meta.thumbs"
						/>
						<img
							v-else-if="file.extension === 'pdf'"
							style="height: 65px; maxwidth: 240px"
							name="image"
							src="@/assets/PDF.png"
						/>
						<h4
							name="title"
							:style="{
								marginTop: '5px',
								marginLeft: '10px',
								marginRight: '10px',
								marginBottom: '0'
							}"
						>
							{{ file.name }}
						</h4>
						<ui-button
							slot="button"
							:style="{ marginTop: '10px' }"
							@click="downloadFile(file.url)"
							>Download</ui-button
						>
					</file-tile>
					<collapsible
						size="large"
						:open="false"
						v-if="this.facebookDocuments.length > 0"
					>
						<h1 slot="title">Social Media Post Examples</h1>
						<div
							slot="body"
							class="roundup-row"
							:style="{ maxHeight: '500px', overflowY: 'scroll' }"
						>
							<file-tile
								v-for="(file, idx) in this.facebookDocuments"
								:key="idx"
							>
								<img
									style="height: 80px; maxwidth: 220px"
									name="image"
									:src="file.meta.thumbs.preview.friendly_url"
									v-if="file.meta.thumbs"
								/>
								<img
									v-else-if="file.extension === 'pdf'"
									style="height: 65px; maxwidth: 240px"
									name="image"
									src="@/assets/PDF.png"
								/>
								<h4
									name="title"
									:style="{
										marginTop: '5px',
										marginLeft: '10px',
										marginRight: '10px',
										marginBottom: '0'
									}"
								>
									{{ file.name }}
								</h4>
								<ui-button
									slot="button"
									:style="{ marginTop: '10px' }"
									@click="downloadFile(file.url)"
									>Download</ui-button
								>
							</file-tile>
						</div>
					</collapsible>
					<collapsible
						size="large"
						:open="false"
						v-if="this.videoDocuments.length > 0"
					>
						<h1 slot="title">Video Examples</h1>
						<div
							slot="body"
							class="roundup-row"
							:style="{ maxHeight: '500px', overflowY: 'scroll' }"
						>
							<file-tile
								v-for="(file, idx) in this.videoDocuments"
								:key="idx"
							>
								<img
									style="height: 80px; maxwidth: 220px"
									name="image"
									:src="file.meta.thumbs.preview.friendly_url"
									v-if="file.meta.thumbs"
								/>
								<img
									v-else-if="file.extension === 'pdf'"
									style="height: 65px; maxwidth: 240px"
									name="image"
									src="@/assets/PDF.png"
								/>
								<h4
									name="title"
									:style="{
										marginTop: '5px',
										marginLeft: '10px',
										marginRight: '10px',
										marginBottom: '0'
									}"
								>
									{{ file.name }}
								</h4>
								<ui-button
									slot="button"
									:style="{ marginTop: '10px' }"
									@click="downloadFile(file.url)"
									>Download</ui-button
								>
							</file-tile>
						</div>
					</collapsible>
					<collapsible
						size="large"
						:open="false"
						v-if="this.emailDocuments.length > 0"
					>
						<h1 slot="title">Email Examples</h1>
						<div
							slot="body"
							class="roundup-row"
							:style="{ maxHeight: '500px', overflowY: 'scroll' }"
						>
							<file-tile
								v-for="(file, idx) in this.emailDocuments"
								:key="idx"
							>
								<img
									style="height: 80px; maxwidth: 220px"
									name="image"
									:src="file.meta.thumbs.preview.friendly_url"
									v-if="file.meta.thumbs"
								/>
								<img
									v-else-if="file.extension === 'pdf'"
									style="height: 65px; maxwidth: 240px"
									name="image"
									src="@/assets/PDF.png"
								/>
								<h4
									name="title"
									:style="{
										marginTop: '5px',
										marginLeft: '10px',
										marginRight: '10px',
										marginBottom: '0'
									}"
								>
									{{ file.name }}
								</h4>
								<ui-button
									slot="button"
									:style="{ marginTop: '10px' }"
									@click="downloadFile(file.url)"
									>Download</ui-button
								>
							</file-tile>
						</div>
					</collapsible>
					<collapsible
						size="large"
						:open="false"
						v-if="this.websiteDocuments.length > 0"
					>
						<h1 slot="title">Website Examples</h1>
						<div
							slot="body"
							class="roundup-row"
							:style="{ maxHeight: '500px', overflowY: 'scroll' }"
						>
							<file-tile
								v-for="(file, idx) in this.websiteDocuments"
								:key="idx"
							>
								<img
									style="height: 80px; maxwidth: 220px"
									name="image"
									:src="file.meta.thumbs.preview.friendly_url"
									v-if="file.meta.thumbs"
								/>
								<img
									v-else-if="file.extension === 'pdf'"
									style="height: 65px; maxwidth: 240px"
									name="image"
									src="@/assets/PDF.png"
								/>
								<h4
									name="title"
									:style="{
										marginTop: '5px',
										marginLeft: '10px',
										marginRight: '10px',
										marginBottom: '0'
									}"
								>
									{{ file.name }}
								</h4>
								<ui-button
									slot="button"
									:style="{ marginTop: '10px' }"
									@click="downloadFile(file.url)"
									>Download</ui-button
								>
							</file-tile>
						</div>
					</collapsible>
				</div>
			</card>
			<div style="flex: 1">
				<marketing-downloads />
			</div>
		</div>
	</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import cloneDeep from 'lodash/cloneDeep'
import { mapGetters, mapActions, mapState } from 'vuex'
import UiLabel from '../../ui/Label'
import Screen from '../../ui/Screen'
import FileTile from '../../ui/FileTile'
import Card from '../../ui/Card'
import Collapsible from '../../ui/Collapsible'
import UiButton from '../../ui/Button'
import MarketingDownloads from '../../layout/MarketingDownloads'
import UiLoader from '../../ui/Loader'

export default {
	name: 'steps-view',
	components: {
		Screen,
		UiButton,
		UiLabel,
		Datepicker,
		Card,
		Collapsible,
		FileTile,
		MarketingDownloads,
		UiLoader
	},
	data() {
		return {
			isLoading: false,
			blog: [],
			institution: cloneDeep(this.$store.state.institution.item)
		}
	},
	computed: {
		...mapGetters([
			'institutionUuid',
			'onboardingStep',
			'hasBrand',
			'launchDate',
			'hasStripe',
			'onWebsite',
			'scheduledCall'
		]),
		...mapState({
			mainDocuments: ({ marketing }) => {
				marketing.items.main.objects.reverse()
				return marketing.items.main.objects
			},
			facebookDocuments: ({ marketing }) =>
				marketing.items.facebook.objects,
			videoDocuments: ({ marketing }) => marketing.items.video.objects,
			websiteDocuments: ({ marketing }) =>
				marketing.items.website.objects,
			emailDocuments: ({ marketing }) => marketing.items.email.objects,
			loading: ({ marketing }) => marketing.isFetching
		}),
		deeplink() {
			return 'https://roundup.app/p/' + this.institution.username
		}
	},
	methods: {
		...mapActions(['getLibrary']),
		downloadFile(url) {
			window.open(url, '_blank')
		}
	},
	mounted() {
		let hsScript = document.createElement('script')
		hsScript.setAttribute(
			'src',
			'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js'
		)
		document.head.appendChild(hsScript)

		this.isLoading = true
		this.getLibrary()
	}
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';
.library {
	.roundup-row {
		display: flex;
		flex-direction: row;
		max-width: 100%;
		flex-wrap: wrap;
		justify-content: left;
	}
	.blog {
		max-height: 750px;
		overflow-y: scroll;
		position: relative;
	}
}
</style>
