<template>
	<section :class="['collapsible', size, hide ? 'collapsed' : '']">
		<div
			class="collapsible-header"
			:class="['header-background--' + header_color]"
			@click="toggleHide()"
		>
			<div class="collapsible-title" :class="[hide ? 'collapsed' : '']">
				<slot name="title"></slot>
				<slot name="subtitle"></slot>
			</div>
			<div class="message">
				<slot name="message"></slot>
			</div>
			<div :class="['caret', hide ? 'collapsed' : '']"></div>
		</div>
		<div :class="['collapsible-body', hide ? 'collapsed' : '']">
			<slot name="body"></slot>
		</div>
		<div class="collapsible-footer" v-if="!hide">
			<slot name="footer"></slot>
		</div>
		<slot></slot>
	</section>
</template>

<script>
export default {
	name: 'roundup-collapsible',
	props: ['size', 'open', 'header_color'],
	data() {
		return {
			hide: false
		}
	},
	computed: {
		hasTitleSlot() {
			return !!this.$slots['title']
		}
	},
	methods: {
		toggleHide() {
			this.hide = !this.hide
		}
	},
	mounted() {
		this.hide = !this.open
	}
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';
.collapsible {
	background: $white;
	box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	border-radius: 14px;
	overflow: hidden;
	width: 100%;
	&.small {
		max-width: 420px;
	}
	&.medium {
		max-width: 600px;
	}
	&.large {
		max-width: 1280px;
	}
	&.collapsed {
		padding: 20px 20px 0px 20px;
	}
	.collapsible-header {
		display: flex;
		position: relative;
		background: $roundup-lighter-grey;
		margin: -20px -20px 0px -20px;
		padding: 15px 20px 15px 20px;
		border-bottom: 1px solid $roundup-light-grey;
		.collapsible-title {
			flex-grow: 1;
			h1 {
				font-size: 24px;
				font-weight: 600;
				margin-top: 5px;
				margin-bottom: 0px;
			}
			&.collapsed {
				color: $roundup-medium-grey;
			}
		}
		.collapsible-buttons {
			flex-grow: 0;
			flex-shrink: 0;
			button {
				margin-top: 0px;
			}
		}
		.caret {
			position: absolute;
			right: 40px;
			top: 20px;
			background: url('../../assets/Carrot.png');
			background-size: contain;
			height: 25px;
			width: 39px;
			&.collapsed {
				transform: scale(0.8) rotate(90deg);
			}
		}
		.message {
			position: absolute;
			right: 100px;
			top: 5px;
			color: $roundup-medium-grey;
		}
		&.header-background--green {
			background: $roundup-light-green;
		}
	}
	.collapsible-body {
		margin-top: 20px;
		&.collapsed {
			margin-top: 0px;
			height: 0px;
		}
	}
	.collapsible-footer {
		margin-top: 20px;
		text-align: center;
	}
}
</style>
